import {
  capitalize,
  flatMap,
  fromPairs,
  get,
  isEmpty,
  isEqual,
  isString,
  keyBy,
  map,
  orderBy,
  startCase,
  toPairs,
  unionWith,
} from "lodash";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

import { conversationStatusEnum } from "@/enums/conversation";
import { customDateRangeEnum } from "@/enums/datetimeEnum";
import { filterLookupEnum, sortByEnum } from "@/enums/filterEnum";
import { mediaMessageTypeEnum } from "@/enums/messageEnum";
import * as reportingEnum from "@/enums/reportingEnum";
import { voiceCallStatusEnum } from "@/enums/voiceCall";
import * as conversationReportingQueries from "@/queries/reportingQueries/conversationReportingQueries";
import voiceReportingQueries from "@/queries/reportingQueries/voiceReportingQueries";
import { MAX_REPORTING_DATE_RANGE_DAYS_LIMIT } from "@/settings";
import * as commonUtils from "@/utils/commonUtils";
import * as dateUtils from "@/utils/dateUtils";
import * as inboxPageUtils from "@/utils/inboxPageUtils";
import { sanitizeString } from "@/utils/textUtils";

momentDurationFormatSetup(moment);

const durationRangeOptions = [
  {
    label: "1-5 min",
    value: reportingEnum.customDurationRangeEnum.oneToFiveMinutes,
  },
  {
    label: "5-10 min",
    value: reportingEnum.customDurationRangeEnum.fiveToTenMinutes,
  },
  {
    label: "10-30 min",
    value: reportingEnum.customDurationRangeEnum.tenToThirtyMinutes,
  },
  {
    label: "30-60 min",
    value: reportingEnum.customDurationRangeEnum.thirtyToSixtyMinutes,
  },
  {
    label: "More than 60 min",
    value: reportingEnum.customDurationRangeEnum.moreThan60Minutes,
  },
];

const speedToAnswerRangeOptions = [
  {
    label: "Within 30 sec",
    value: reportingEnum.customSpeedToAnswerRangeEnum.withinThirtySeconds,
  },
  {
    label: "30-60 sec",
    value: reportingEnum.customSpeedToAnswerRangeEnum.thirtyToSixtySeconds,
  },
  {
    label: "1-3 min",
    value: reportingEnum.customSpeedToAnswerRangeEnum.oneToThreeMinutes,
  },
  {
    label: "More than 3 min",
    value: reportingEnum.customSpeedToAnswerRangeEnum.moreThan3Minutes,
  },
];

export const callStatusDateTimestampFieldNameMapping = {
  [voiceCallStatusEnum.ABANDONED]: "abandonedAt",
  [voiceCallStatusEnum.BUSY]: "busyAt",
  [voiceCallStatusEnum.CANCELLED]: "cancelledAt",
  [voiceCallStatusEnum.DECLINED]: "declinedAt",
  [voiceCallStatusEnum.DISCONNECTED]: "disconnectedAt",
  [voiceCallStatusEnum.ENDED]: "endedAt",
  [voiceCallStatusEnum.FAILED]: "failedAt",
  [voiceCallStatusEnum.IVR_ABANDONED]: "ivrAbandonedAt",
  [voiceCallStatusEnum.VOICEMAIL]: "leftVoicemailAt",
  [voiceCallStatusEnum.MISSED]: "missedAt",
  [voiceCallStatusEnum.MOVED_TO_CHAT]: "movedToChatAt",
  [voiceCallStatusEnum.SHORT_ABANDONED]: "shortAbandonedAt",
  [voiceCallStatusEnum.TIMEOUT]: "timeoutAt",
  [voiceCallStatusEnum.TRANSFER_DECLINED]: "transferDeclinedAt",
  [voiceCallStatusEnum.TRANSFER_FAILED]: "transferFailedAt",
  [voiceCallStatusEnum.TRANSFER_VOICEMAIL]: "transferLeftVoicemailAt",
  [voiceCallStatusEnum.TRANSFER_MISSED]: "transferMissedAt",
  [voiceCallStatusEnum.TRANSFER_MOVED_TO_CHAT]: "transferMovedToChatAt",
  [voiceCallStatusEnum.TRANSFER_TIMEOUT]: "transferTimeoutAt",
};

const getCommonTagFilterData = ({ placeholder }) => {
  return {
    query: conversationReportingQueries.GET_CONTENT_TYPE_ALLOWED_TAGS,
    queryObjectListPath: "contentTypeAllowedTags",
    selectorValue: [],
    SelectProps: { placeholder, isMulti: true },
    selectorOptionSettings: {
      valuePath: "contentTypeAllowedTagId",
      labelPath: "tag.name",
    },
    getAdditionalVariables: ({ searchTerm, filter }) => ({
      contentType: reportingEnum.tagFilterTypeEnum[filter.filterType],
      nameSearch: searchTerm,
    }),
  };
};

const conversationTagFilter = {
  filter: {
    filterType: reportingEnum.reportingFilterTypeEnum.conversationTags,
    label: "Conversation Tags",
    ...getCommonTagFilterData({ placeholder: "Filter by conversation tag" }),
  },
};

const contactTagFilter = {
  filter: {
    filterType: reportingEnum.reportingFilterTypeEnum.contactTags,
    label: "Contact Tags",
    ...getCommonTagFilterData({ placeholder: "Filter by contact tag" }),
  },
};

const tagFilters = [conversationTagFilter, contactTagFilter];

export const defaultReportingFilters = [...tagFilters];

export const unattendedConversationReportingFilters = [
  ...tagFilters,
  {
    filter: {
      filterType: reportingEnum.reportingFilterTypeEnum.group,
      label: "Groups",
      query: conversationReportingQueries.GET_GROUPS,
      queryObjectListPath: "groups",
      selectorValue: null,
      selectorOptionSettings: {
        valuePath: "id",
        labelPath: "name",
      },
      SelectProps: { placeholder: "All groups" },
      getAdditionalVariables: ({ searchTerm }) => ({ nameSearch: searchTerm }),
    },
  },
  {
    filter: {
      filterType: reportingEnum.reportingFilterTypeEnum.agent,
      label: "Conversation Agent",
      query: conversationReportingQueries.GET_AGENTS,
      queryObjectListPath: "agents.results",
      selectorValue: null,
      selectorOptionSettings: {
        valuePath: "id",
        labelPath: ["user.firstName", "user.lastName"],
        concatenateLabel: true,
      },
      SelectProps: { placeholder: "All agents" },
      getAdditionalVariables: ({ searchTerm }) => ({ nameSearch: searchTerm }),
    },
  },
];

export const voiceCallLogsFiltersValues = [
  {
    filter: {
      filterType: reportingEnum.voiceCallLogsFilterTypeEnum.callDirection,
      label: "Call Direction",
      query: voiceReportingQueries.GET_VOICE_CALL_DIRECTION,
      queryObjectListPath: "__type.enumValues",
      selectorValue: null,
      SelectProps: { placeholder: "Both directions" },
      selectorOptionSettings: {
        valuePath: "name",
        labelPath: "name",
        labelFormat: (status) => capitalize(startCase(status)),
      },
    },
  },
  contactTagFilter,
  {
    filter: {
      filterType: reportingEnum.voiceCallLogsFilterTypeEnum.firstAgentInCall,
      label: "First Agent in Call",
      query: conversationReportingQueries.GET_AGENTS,
      queryObjectListPath: "agents.results",
      selectorValue: [],
      SelectProps: {
        isMulti: true,
        placeholder: "All agents",
      },
      selectorOptionSettings: {
        valuePath: "id",
        labelPath: ["user.firstName", "user.lastName"],
        concatenateLabel: true,
      },
      getAdditionalVariables: ({ searchTerm }) => ({ nameSearch: searchTerm }),
    },
  },
  conversationTagFilter,
  {
    filter: {
      filterType: reportingEnum.reportingFilterTypeEnum.group,
      label: "Conversation Groups",
      query: voiceReportingQueries.GET_GROUPS,
      queryObjectListPath: "groups",
      selectorValue: [],
      SelectProps: {
        isMulti: true,
        placeholder: "All groups",
      },
      selectorOptionSettings: { valuePath: "id", labelPath: "name" },
      getAdditionalVariables: ({ searchTerm }) => ({ nameSearch: searchTerm }),
    },
  },
  {
    filter: {
      filterType: reportingEnum.reportingFilterTypeEnum.agent,
      label: "Conversation Agents",
      query: voiceReportingQueries.GET_AGENTS,
      queryObjectListPath: "agents.results",
      selectorValue: [],
      SelectProps: {
        isMulti: true,
        placeholder: "All agents",
      },
      selectorOptionSettings: {
        valuePath: "id",
        labelPath: ["user.firstName", "user.lastName"],
        concatenateLabel: true,
      },
      getAdditionalVariables: ({ searchTerm }) => ({ nameSearch: searchTerm }),
    },
  },
  {
    filter: {
      filterType: reportingEnum.voiceCallLogsFilterTypeEnum.callStarted,
      label: "Call Started",
      selectorValue: {
        label: "Last 24 hours",
        value: customDateRangeEnum.last24Hours,
        dateRange: dateUtils.getDateRange(customDateRangeEnum.last24Hours),
      },
      SelectProps: {
        components: { ClearIndicator: null },
        options: dateUtils.dateRangeOptions,
        placeholder: "Anytime",
      },
    },
  },
  {
    filter: {
      filterType: reportingEnum.voiceCallLogsFilterTypeEnum.duration,
      label: "Duration",
      selectorValue: null,
      SelectProps: {
        options: durationRangeOptions,
        placeholder: "Any duration",
      },
    },
  },
  {
    filter: {
      filterType: reportingEnum.voiceCallLogsFilterTypeEnum.speedToAnswer,
      label: "Time to Answer",
      selectorValue: null,
      SelectProps: {
        options: speedToAnswerRangeOptions,
        placeholder: "Anytime",
      },
    },
  },
  {
    filter: {
      filterType: reportingEnum.voiceCallLogsFilterTypeEnum.status,
      label: "Status",
      query: voiceReportingQueries.GET_VOICE_CALL_STATUS,
      queryObjectListPath: "__type.enumValues",
      selectorValue: null,
      SelectProps: { placeholder: "Any status" },
      selectorOptionSettings: {
        valuePath: "name",
        labelPath: "name",
        labelFormat: (status) => capitalize(startCase(status)),
      },
    },
  },
];

const prepareDefaultVoiceReportingOverviewTabMapping = (
  voiceReportOverviewTab,
) => ({
  [reportingEnum.reportingCategoryEnum.team]: voiceReportOverviewTab,
  [reportingEnum.reportingCategoryEnum.groups]: voiceReportOverviewTab,
  [reportingEnum.reportingCategoryEnum.agents]: voiceReportOverviewTab,
  [reportingEnum.reportingCategoryEnum.voiceProviderAccounts]:
    voiceReportOverviewTab,
});

export const defaultReportingFormData = {
  /* Persistent form data that can be accessed in both conversation/voice report pages */
  filters: defaultReportingFilters,
  voiceCallLogsFilters: voiceCallLogsFiltersValues,
  unattendedConversationFilters: unattendedConversationReportingFilters,
  dateRange: {
    type: customDateRangeEnum.custom,
    ...dateUtils.getLastNDaysDateRange(7),
  },
  overviewTabKeyData: {
    [reportingEnum.reportingQueryNameEnum.conversation]: {
      [reportingEnum.reportingCategoryEnum.team]:
        reportingEnum.conversationReportingOverviewTabEnum
          .totalNewConversations,
      [reportingEnum.reportingCategoryEnum.groups]:
        reportingEnum.conversationReportingOverviewTabEnum
          .totalNewConversations,
      [reportingEnum.reportingCategoryEnum.agents]:
        reportingEnum.conversationReportingOverviewTabEnum
          .totalNewConversations,
    },
    [reportingEnum.reportingQueryNameEnum.voice]: {
      [reportingEnum.voiceReportingTypeTabEnum.allCalls]:
        prepareDefaultVoiceReportingOverviewTabMapping(
          reportingEnum.voiceReportingOverviewTabEnum.totalAnsweredCallCount,
        ),
      [reportingEnum.voiceReportingTypeTabEnum.outboundCalls]:
        prepareDefaultVoiceReportingOverviewTabMapping(
          reportingEnum.voiceReportingOverviewTabEnum.outboundCallCount,
        ),
      [reportingEnum.voiceReportingTypeTabEnum.inboundCalls]:
        prepareDefaultVoiceReportingOverviewTabMapping(
          reportingEnum.voiceReportingOverviewTabEnum.inboundCallCount,
        ),
    },
    [reportingEnum.reportingQueryNameEnum.messagingOutcomeReporting]: {
      [reportingEnum.reportingCategoryEnum.messagingOutcomeReport]:
        reportingEnum.messageOutcomeReportingOverviewTabEnum
          .contactsAttemptedCount,
    },
  },
  messagingOutcomeSectionTabKeyData: {
    [reportingEnum.reportingCategoryEnum.messagingOutcomeReport]:
      reportingEnum.messagingOutcomeSectionTabEnum.uniqueContacts,
  },
  voiceReportTableTabKeyData: {
    [reportingEnum.reportingCategoryEnum.groups]:
      reportingEnum.voiceReportingTableTabEnum.allCalls,
    [reportingEnum.reportingCategoryEnum.agents]:
      reportingEnum.voiceReportingTableTabEnum.allCalls,
    [reportingEnum.reportingCategoryEnum.voiceProviderAccounts]:
      reportingEnum.voiceReportingTableTabEnum.allCalls,
  },
  /* The data below can only be accessed in voice report pages */
  voiceCallTypeTabKeyData: {
    [reportingEnum.reportingCategoryEnum.team]:
      reportingEnum.voiceReportingTypeTabEnum.allCalls,
    [reportingEnum.reportingCategoryEnum.groups]:
      reportingEnum.voiceReportingTypeTabEnum.allCalls,
    [reportingEnum.reportingCategoryEnum.agents]:
      reportingEnum.voiceReportingTypeTabEnum.allCalls,
    [reportingEnum.reportingCategoryEnum.voiceProviderAccounts]:
      reportingEnum.voiceReportingTypeTabEnum.allCalls,
  },
  /* The data below can only be accessed in conversation report pages */
  csatRange: [1, 5],
  withFeedback: true,
};

export const voiceReportingTypeTabsOptions = [
  reportingEnum.voiceReportingTypeTabEnum.allCalls,
  reportingEnum.voiceReportingTypeTabEnum.outboundCalls,
  reportingEnum.voiceReportingTypeTabEnum.inboundCalls,
];

export const messagingOutcomeSectionTabsOptions = [
  reportingEnum.messagingOutcomeSectionTabEnum.uniqueContacts,
];

export const voiceReportingTableTabsOptions = [
  reportingEnum.voiceReportingTableTabEnum.allCalls,
  reportingEnum.voiceReportingTableTabEnum.uniqueContacts,
];

const voiceCallReportQueryObjectMapping = {
  [reportingEnum.voiceReportingTypeTabEnum.allCalls]: "allVoiceCallReport",
  [reportingEnum.voiceReportingTypeTabEnum.outboundCalls]:
    "outboundVoiceCallReport",
  [reportingEnum.voiceReportingTypeTabEnum.inboundCalls]:
    "inboundVoiceCallReport",
};

/* A comparator function used when sorting reporting table data */
const emptyValueSortFn = ({ data, sortBy, isAscending }) => {
  const cellData = data[sortBy];
  if (!cellData && !isString(cellData)) {
    /* Put empty value (0, "", null, undefined) at the end of array */
    return isAscending ? Infinity : -Infinity;
  }

  return cellData;
};

export const getSortedReportingTableData = ({
  data,
  sortBy,
  sortDirection,
  customComparators = [],
}) => {
  const isAscending = sortDirection === sortByEnum.ASC;
  const sortOrder = isAscending ? "asc" : "desc";

  const sortComparators = [
    (data) => emptyValueSortFn({ data, sortBy, isAscending }),
    ...customComparators,
  ];

  return orderBy(
    data,
    sortComparators,
    sortComparators.map(() => sortOrder),
  );
};

export const REPORTING_DATE_INPUT_FORMAT = "DD-MM-YYYY";

export const isValidDateInputValue = (input) => {
  return moment(input, REPORTING_DATE_INPUT_FORMAT, true).isValid();
};

export const getOnlyValidDateRange = (dateRange) => {
  const { from, to } = dateRange;

  return {
    from: isValidDateInputValue(from) ? from : null,
    to: isValidDateInputValue(to) ? to : null,
  };
};

export const getDateRangeLimit = ({ from, to }) => {
  const isDateRangeEmpty = !from && !to;

  if (isDateRangeEmpty) return { minDate: null, maxDate: null };

  return {
    minDate: moment(to || from)
      .subtract(MAX_REPORTING_DATE_RANGE_DAYS_LIMIT, "days")
      .startOf("day")
      .toDate(),
    maxDate: moment(from || to)
      .add(MAX_REPORTING_DATE_RANGE_DAYS_LIMIT, "days")
      .startOf("day")
      .toDate(),
  };
};

const getDatetimeDifference = (startDatetime, endDatetime) => {
  const isSameDay = endDatetime.isSame(startDatetime, "day");
  const isBetween7Days =
    Math.round(endDatetime.diff(startDatetime, "days", true)) <= 7;
  const isSameYear = endDatetime.isSame(startDatetime, "year");

  return { isSameDay, isBetween7Days, isSameYear };
};

const getChartTruncateType = ({ startDatetime, endDatetime }) => {
  const { isSameDay, isBetween7Days } = getDatetimeDifference(
    moment(startDatetime),
    moment(endDatetime),
  );

  if (isSameDay || isBetween7Days) return filterLookupEnum.HOUR;

  return filterLookupEnum.DAY;
};

export const getConversationStatusLabelColor = ({ status, theme }) => {
  switch (status) {
    case conversationStatusEnum.active:
      return theme.palette.success.light;
    default:
      return theme.palette.grey[1590];
  }
};

export const getCallStatusLabelColor = ({ status, theme }) => {
  switch (status) {
    case voiceCallStatusEnum.ANSWERED:
    case voiceCallStatusEnum.ANSWERED_BY_MACHINE: {
      return theme.palette.success[1650];
    }

    case voiceCallStatusEnum.ABANDONED:
    case voiceCallStatusEnum.SHORT_ABANDONED:
    case voiceCallStatusEnum.DECLINED:
    case voiceCallStatusEnum.MISSED:
    case voiceCallStatusEnum.CANCELLED:
    case voiceCallStatusEnum.BUSY:
    case voiceCallStatusEnum.DISCONNECTED:
    case voiceCallStatusEnum.FAILED:
    case voiceCallStatusEnum.TRANSFER_DECLINED:
    case voiceCallStatusEnum.TRANSFER_MISSED:
    case voiceCallStatusEnum.TRANSFER_FAILED: {
      return theme.palette.alert[420];
    }

    case voiceCallStatusEnum.ENDED:
    case voiceCallStatusEnum.VOICEMAIL:
    case voiceCallStatusEnum.IVR_ABANDONED:
    case voiceCallStatusEnum.TRANSFER_ENDED:
    case voiceCallStatusEnum.TRANSFER_VOICEMAIL: {
      return theme.palette.grey[1590];
    }

    case voiceCallStatusEnum.TRANSFER_MOVED_TO_CHAT:
    case voiceCallStatusEnum.TRANSFER_TIMEOUT:
    case voiceCallStatusEnum.MOVED_TO_CHAT:
    case voiceCallStatusEnum.TIMEOUT:
    case voiceCallStatusEnum.PENDING: {
      return theme.palette.textSecondary.main;
    }

    default: {
      return theme.palette.textPrimary.main;
    }
  }
};

export const getConversationReportOverviewData = (data) => {
  if (!data) return [];

  const totalNewConversations = data.conversationStartedReport?.total;
  const { averageTotal: averageMessagePerConversation, total: totalMessages } =
    data.messagesPerConversationReport?.metrics || {};
  const averageResolutionTime = data.agentResolutionTimeReport?.average;
  const averageFirstResponseTime =
    data.firstResponseTimeIncludeWorkingHourReport?.average;
  const averageResponseTime =
    data.responseTimeIncludeWorkingHourReport?.average;
  const averageCSAT = data.csatReport?.average;
  const totalUnattendedConversations = data.unattendedConversationReport?.total;

  return [
    {
      key: reportingEnum.conversationReportingOverviewTabEnum
        .totalNewConversations,
      caption: "New Conversations",
      subCaption: "Total",
      count: commonUtils.formatNumber(totalNewConversations),
    },
    {
      key: reportingEnum.conversationReportingOverviewTabEnum.totalMessages,
      caption: "Messages",
      subCaption: "Total",
      count: commonUtils.formatNumber(totalMessages),
    },
    {
      key: reportingEnum.conversationReportingOverviewTabEnum
        .averageFirstReplySpeed,
      caption: "First Reply Speed",
      subCaption: "Average",
      count: dateUtils.formatDuration(averageFirstResponseTime),
    },
    {
      key: reportingEnum.conversationReportingOverviewTabEnum.averageReplySpeed,
      caption: "Reply Speed",
      subCaption: "Average",
      count: dateUtils.formatDuration(averageResponseTime),
    },
    {
      key: reportingEnum.conversationReportingOverviewTabEnum
        .averageMessagesPerConversation,
      caption: "Messages/Conversation",
      subCaption: "Average",
      count: commonUtils.formatNumber(averageMessagePerConversation, 1),
    },
    {
      key: reportingEnum.conversationReportingOverviewTabEnum
        .averageResolutionTime,
      caption: "Resolution Time",
      subCaption: "Average",
      count: dateUtils.formatDuration(averageResolutionTime),
    },
    {
      key: reportingEnum.conversationReportingOverviewTabEnum.averageCSAT,
      caption: "CSAT",
      subCaption: "Average",
      count: averageCSAT ? averageCSAT.toFixed(2) : "-",
    },
    {
      key: reportingEnum.conversationReportingOverviewTabEnum
        .totalUnattendedConversations,
      caption: "Unattended",
      subCaption: "Total",
      count: commonUtils.formatNumber(totalUnattendedConversations),
    },
  ];
};

export const getMessageOutcomeReportOverviewData = (data) => {
  const {
    contactsAttemptedCount,
    contactsSuccessfulCount,
    contactsReadCount,
    contactsPendingCount,
    contactsFailureCount,
  } = data?.metrics || {};

  return [
    {
      key: reportingEnum.messageOutcomeReportingOverviewTabEnum
        .contactsAttemptedCount,
      caption: "Contact attempted",
      tooltipTitle: "Total number of attempted contacts to unique contacts",
      count: commonUtils.formatNumber(contactsAttemptedCount),
    },
    {
      key: reportingEnum.messageOutcomeReportingOverviewTabEnum
        .contactsSuccessfulCount,
      caption: "Messaged successfully",
      tooltipTitle:
        "Number of unique contacts that had at least 1 message sent and received by contact successfully",
      count: commonUtils.formatNumber(contactsSuccessfulCount),
      percentage: commonUtils.getPercentage(
        contactsSuccessfulCount,
        contactsAttemptedCount,
      ),
    },
    {
      key: reportingEnum.messageOutcomeReportingOverviewTabEnum
        .contactsReadCount,
      caption: "Read > 1 message",
      tooltipTitle:
        "Number of unique contacts that have read at least 1 message",
      count: commonUtils.formatNumber(contactsReadCount),
      percentage: commonUtils.getPercentage(
        contactsReadCount,
        contactsAttemptedCount,
      ),
    },
    {
      key: reportingEnum.messageOutcomeReportingOverviewTabEnum
        .contactsPendingCount,
      caption: "Pending delivery",
      tooltipTitle:
        "Number of unique contacts that have yet to receive all messages sent (all messages must have 1 tick)",
      count: commonUtils.formatNumber(contactsPendingCount, 1),
      percentage: commonUtils.getPercentage(
        contactsPendingCount,
        contactsAttemptedCount,
      ),
    },
    {
      key: reportingEnum.messageOutcomeReportingOverviewTabEnum
        .contactsFailureCount,
      caption: "Unique number that all messages had fail to sent",
      tooltipTitle:
        "Number of unique contacts that have sending errors for all messages sent",
      count: commonUtils.formatNumber(contactsFailureCount),
      percentage: commonUtils.getPercentage(
        contactsFailureCount,
        contactsAttemptedCount,
      ),
    },
  ];
};

export const getVoiceReportOverviewData = ({
  data,
  currentVoiceCallTypeTabKey,
}) => {
  if (!data) return [];

  const {
    allVoiceCallReport,
    inboundVoiceCallReport,
    outboundVoiceCallReport,
  } = data;

  const { metrics: allVoiceCallMetrics = {} } = allVoiceCallReport || {};
  const { metrics: inboundVoiceCallMetrics = {} } =
    inboundVoiceCallReport || {};
  const { metrics: outboundVoiceCallMetrics = {} } =
    outboundVoiceCallReport || {};

  switch (currentVoiceCallTypeTabKey) {
    case reportingEnum.voiceReportingTypeTabEnum.allCalls: {
      const answeredInboundCallCount =
        inboundVoiceCallMetrics.answeredCallCount || 0;
      const answeredOutboundCallCount =
        outboundVoiceCallMetrics.answeredCallCount || 0;
      const totalAnsweredCallCount =
        answeredInboundCallCount + answeredOutboundCallCount;

      const missedInboundCallCount =
        inboundVoiceCallMetrics.missedCallCount || 0;
      const missedOutboundCallCount =
        outboundVoiceCallMetrics.missedCallCount || 0;
      const totalMissedCallCount =
        missedInboundCallCount + missedOutboundCallCount;

      const inboundCallTime = inboundVoiceCallMetrics.totalCallTime || 0;
      const outboundCallTime = outboundVoiceCallMetrics.totalCallTime || 0;
      const totalCallTime = inboundCallTime + outboundCallTime;

      return [
        {
          key: reportingEnum.voiceReportingOverviewTabEnum
            .totalAnsweredCallCount,
          caption: "Connected voice calls",
          subCaption: "Total",
          count: commonUtils.formatNumber(totalAnsweredCallCount),
        },
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.inboundCallCount,
          caption: "Connected inbound calls",
          subCaption: "Total",
          count: commonUtils.formatNumber(answeredInboundCallCount),
        },
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.outboundCallCount,
          caption: "Connected outbound calls",
          subCaption: "Total",
          count: commonUtils.formatNumber(answeredOutboundCallCount),
        },
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.totalMissedCallCount,
          caption: "Missed calls",
          subCaption: "Total",
          count: commonUtils.formatNumber(totalMissedCallCount),
        },
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.totalCallTime,
          caption: "Calls duration",
          subCaption: "Total",
          count: dateUtils.formatDuration(totalCallTime),
        },
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.averageCallTime,
          caption: "Duration / call",
          subCaption: "Average",
          count: dateUtils.formatDuration(allVoiceCallMetrics.averageCallTime),
        },
      ];
    }

    case reportingEnum.voiceReportingTypeTabEnum.outboundCalls: {
      const answeredOutboundCallCount =
        outboundVoiceCallMetrics.answeredCallCount || 0;
      const totalCallTime = outboundVoiceCallMetrics.totalCallTime || 0;
      const averageCallTime = outboundVoiceCallMetrics.averageCallTime || 0;
      const missedCallCount = outboundVoiceCallMetrics.missedCallCount || 0;
      const cancelledCallCount =
        outboundVoiceCallMetrics.cancelledCallCount || 0;

      return [
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.outboundCallCount,
          caption: "Connected outbound calls",
          subCaption: "Total",
          count: commonUtils.formatNumber(answeredOutboundCallCount),
        },
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.totalCallTime,
          caption: "Calls duration",
          subCaption: "Total",
          count: dateUtils.formatDuration(totalCallTime),
        },
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.averageCallTime,
          caption: "Duration / call",
          subCaption: "Average",
          count: dateUtils.formatDuration(averageCallTime),
        },
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.missedCallCount,
          caption: "Missed outbound calls",
          subCaption: "Total",
          count: commonUtils.formatNumber(missedCallCount),
        },
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.cancelledCallCount,
          caption: "Cancelled calls",
          subCaption: "Total",
          count: commonUtils.formatNumber(cancelledCallCount),
        },
      ];
    }

    case reportingEnum.voiceReportingTypeTabEnum.inboundCalls: {
      const answeredInboundCallCount =
        inboundVoiceCallMetrics.answeredCallCount || 0;
      const totalCallTime = inboundVoiceCallMetrics.totalCallTime || 0;
      const averageCallTime = inboundVoiceCallMetrics.averageCallTime || 0;
      const missedCallCount = inboundVoiceCallMetrics.missedCallCount || 0;
      const voicemailCount = inboundVoiceCallMetrics.voicemailCount || 0;

      return [
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.inboundCallCount,
          caption: "Connected inbound calls",
          subCaption: "Total",
          count: commonUtils.formatNumber(answeredInboundCallCount),
        },
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.totalCallTime,
          caption: "Calls duration",
          subCaption: "Total",
          count: dateUtils.formatDuration(totalCallTime),
        },
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.averageCallTime,
          caption: "Duration / call",
          subCaption: "Average",
          count: dateUtils.formatDuration(averageCallTime),
        },
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.missedCallCount,
          caption: "Missed inbound calls",
          subCaption: "Total",
          count: commonUtils.formatNumber(missedCallCount),
        },
        {
          key: reportingEnum.voiceReportingOverviewTabEnum.voicemailCount,
          caption: "Voicemails",
          subCaption: "Total",
          count: commonUtils.formatNumber(voicemailCount),
        },
      ];
    }
  }
};

export const prepareConversationReportTableData = (tableData) => {
  if (!tableData) return [];

  return tableData.map((data) => {
    const {
      id,
      firstResponseTimeIncludeWorkingHourReport,
      responseTimeIncludeWorkingHourReport,
      messagesPerConversationReport,
      totalMessagesSentReport,
      conversationStartedReport,
      conversationResolvedReport,
      csatReport,
    } = data;

    return {
      id,
      name: data.name || commonUtils.getAgentFullName(data),
      ...(data?.user?.email && { email: data.user.email }),
      averageFirstReplySpeed:
        firstResponseTimeIncludeWorkingHourReport?.average,
      averageReplySpeed: responseTimeIncludeWorkingHourReport?.average,
      averageMessagesPerConversation:
        messagesPerConversationReport?.metrics?.averageTotal,
      averageOutboundMessagesPerConversation:
        messagesPerConversationReport?.metrics?.averageOutbound,
      averageInboundMessagesPerConversation:
        messagesPerConversationReport?.metrics?.averageInbound,
      totalOutbound: messagesPerConversationReport?.metrics?.outbound,
      totalInbound: messagesPerConversationReport?.metrics?.inbound,
      mediaCount: totalMessagesSentReport?.total?.mediaCount,
      totalResolved: conversationResolvedReport?.total,
      totalStarted: conversationStartedReport?.total,
      csat: csatReport?.average,
    };
  });
};

const prepareVoiceCallSummaryReportAllCallsTableData = (tableData) => {
  return tableData.map((data) => {
    const { id, inboundVoiceCallReport, outboundVoiceCallReport } = data;

    const { metrics: inboundVoiceCallMetrics = {} } =
      inboundVoiceCallReport || {};
    const {
      answeredCallCount: answeredInboundCallCount,
      totalCallCount: totalInboundCallCount,
      voicemailCount = 0,
    } = inboundVoiceCallMetrics || {};

    const { metrics: outboundVoiceCallMetrics = {} } =
      outboundVoiceCallReport || {};
    const {
      answeredCallCount: answeredOutboundCallCount,
      totalCallCount: totalOutboundCallCount,
    } = outboundVoiceCallMetrics;

    const totalAnsweredCallCount =
      (answeredInboundCallCount || 0) + (answeredOutboundCallCount || 0);
    const totalVoiceCallCount =
      (inboundVoiceCallMetrics.totalCallCount || 0) +
      (outboundVoiceCallMetrics.totalCallCount || 0);

    const inboundCallDuration = inboundVoiceCallMetrics.totalCallTime || 0;
    const outboundCallDuration = outboundVoiceCallMetrics.totalCallTime || 0;
    const totalCallDuration = inboundCallDuration + outboundCallDuration;

    const missedInboundCallCount = inboundVoiceCallMetrics.missedCallCount || 0;
    const missedOutboundCallCount =
      outboundVoiceCallMetrics.missedCallCount || 0;
    const totalMissedCallCount =
      missedInboundCallCount + missedOutboundCallCount;

    const totalAnsweredCallsPercentage = commonUtils.getPercentage(
      totalAnsweredCallCount,
      totalVoiceCallCount,
    );
    const answeredInboundCallsPercentage = commonUtils.getPercentage(
      answeredInboundCallCount,
      totalInboundCallCount,
    );
    const answeredOutboundCallsPercentage = commonUtils.getPercentage(
      answeredOutboundCallCount,
      totalOutboundCallCount,
    );

    return {
      id,
      name: data.name || commonUtils.getAgentFullName(data),
      ...(data?.user?.email && { email: data.user.email }),
      ...(data?.accountId && { accountId: data.accountId }),
      totalAnsweredCallCount,
      totalVoiceCallCount,
      answeredInboundCallCount,
      totalInboundCallCount,
      answeredOutboundCallCount,
      totalOutboundCallCount,
      totalCallDuration,
      inboundCallDuration,
      outboundCallDuration,
      missedInboundCallCount,
      missedOutboundCallCount,
      totalMissedCallCount,
      voicemailCount,
      totalAnsweredCallsPercentage,
      answeredInboundCallsPercentage,
      answeredOutboundCallsPercentage,
    };
  });
};

const prepareVoiceCallSummaryReportUniqueContactsTableData = (tableData) => {
  return tableData.map((data) => {
    const { id, inboundVoiceCallReport, outboundVoiceCallReport } = data;

    const { metrics: inboundVoiceCallMetrics = {} } =
      inboundVoiceCallReport || {};
    const {
      uniqueAnsweredCallCount: uniqueAnsweredInboundCallCount,
      uniqueTotalCallCount: uniqueTotalInboundCallCount,
    } = inboundVoiceCallMetrics || {};

    const { metrics: outboundVoiceCallMetrics = {} } =
      outboundVoiceCallReport || {};
    const {
      uniqueAnsweredCallCount: uniqueAnsweredOutboundCallCount,
      uniqueTotalCallCount: uniqueTotalOutboundCallCount,
    } = outboundVoiceCallMetrics;

    const totalAnsweredCallCount =
      (uniqueAnsweredInboundCallCount || 0) +
      (uniqueAnsweredOutboundCallCount || 0);
    const totalVoiceCallCount =
      (uniqueTotalInboundCallCount || 0) + (uniqueTotalOutboundCallCount || 0);

    const totalAnsweredCallsPercentage = commonUtils.getPercentage(
      totalAnsweredCallCount,
      totalVoiceCallCount,
    );
    const uniqueAnsweredInboundCallsPercentage = commonUtils.getPercentage(
      uniqueAnsweredInboundCallCount,
      uniqueTotalInboundCallCount,
    );
    const uniqueAnsweredOutboundCallsPercentage = commonUtils.getPercentage(
      uniqueAnsweredOutboundCallCount,
      uniqueTotalOutboundCallCount,
    );

    return {
      id,
      name: data.name || commonUtils.getAgentFullName(data),
      ...(data?.user?.email && { email: data.user.email }),
      ...(data?.accountId && { accountId: data.accountId }),
      totalAnsweredCallCount,
      totalVoiceCallCount,
      uniqueAnsweredInboundCallCount,
      uniqueTotalInboundCallCount,
      uniqueAnsweredOutboundCallCount,
      uniqueTotalOutboundCallCount,
      totalAnsweredCallsPercentage,
      uniqueAnsweredInboundCallsPercentage,
      uniqueAnsweredOutboundCallsPercentage,
    };
  });
};

export const prepareVoiceCallSummaryReportTableData = ({
  tableData,
  currentVoiceReportTableTabKey,
}) => {
  if (!tableData) return [];

  switch (currentVoiceReportTableTabKey) {
    case reportingEnum.voiceReportingTableTabEnum.allCalls: {
      return prepareVoiceCallSummaryReportAllCallsTableData(tableData);
    }

    case reportingEnum.voiceReportingTableTabEnum.uniqueContacts: {
      return prepareVoiceCallSummaryReportUniqueContactsTableData(tableData);
    }
  }
};

const prepareInvalidMessageData = () => {
  return {
    message: "Invalid response",
    isInvalid: true,
  };
};

const getMediaConversationMessageData = (mediaConversationMessage) => {
  const { caption, media, mediaMessageType } = mediaConversationMessage;

  const isValidMessageType = [
    mediaMessageTypeEnum.image,
    mediaMessageTypeEnum.video,
  ].includes(mediaMessageType);

  if (!isValidMessageType) return prepareInvalidMessageData();

  return {
    message: sanitizeString(caption),
    src: media.mediaFileUrl,
    mediaMessageType,
  };
};

const prepareAdditionalFeedback = (messages) => {
  if (!messages) return null;

  const { textConversationMessage, mediaConversationMessage } = messages;

  if (textConversationMessage) {
    return {
      message: sanitizeString(textConversationMessage.body),
    };
  }

  if (mediaConversationMessage) {
    return getMediaConversationMessageData(mediaConversationMessage);
  }

  return prepareInvalidMessageData();
};

export const prepareTagsData = (tags) => {
  if (isEmpty(tags)) return [];

  return tags.map((tag) => ({
    id: tag.contentTypeAllowedTag.id,
    name: tag.contentTypeAllowedTag.tag.name,
  }));
};

const getCSATReportData = (data, reportingCategory) => {
  if (!data) return null;

  switch (reportingCategory) {
    case reportingEnum.reportingCategoryEnum.team: {
      return data.reporting.team.csatReport;
    }
    case reportingEnum.reportingCategoryEnum.groups: {
      return data.reporting.groups[0].csatReport;
    }
    case reportingEnum.reportingCategoryEnum.groupAgent: {
      return data.reporting.groups[0].agents[0].csatReport;
    }
    case reportingEnum.reportingCategoryEnum.agents: {
      return data.reporting.agents[0].csatReport;
    }
    default: {
      return data;
    }
  }
};

export const prepareCSATReportTableData = ({
  csatReport,
  reportingCategory,
}) => {
  const csatReportData = getCSATReportData(csatReport, reportingCategory);

  if (!csatReportData) return [];

  const { data: tableData, average } = csatReportData;

  return tableData.map((data) => {
    const { additionalFeedback, conversation, score } = data;
    const { contact, conversationTags, group, resolver, resolvedAt } =
      conversation;

    const parsedAdditionalFeedback =
      prepareAdditionalFeedback(additionalFeedback);

    return {
      conversationId: conversation.id,
      average,
      additionalFeedback: parsedAdditionalFeedback,
      contact,
      contactFullName: contact?.fullName,
      group: group || {},
      groupName: group?.name,
      score,
      resolvedBy: inboxPageUtils.getConversationEventUnionObjectLabel(resolver),
      resolvedAt: moment(resolvedAt).format("YYYY-MM-DD HH:mm"),
      conversationTags: prepareTagsData(conversationTags),
    };
  });
};

export const prepareUnattendedReportTableData = (reportData) => {
  if (!reportData) return [];

  const { data: tableData, total } = reportData;

  return tableData.map((data) => {
    const { agent, contact, conversation, group, startDatetime, waitTime } =
      data;

    return {
      total,
      agent,
      contact,
      contactDisplayName: contact?.displayName,
      conversation,
      group: group || {},
      groupName: group?.name,
      waitTime,
      unattendedBy: commonUtils.getAgentFullName(agent),
      unattendedAt: moment(startDatetime).format("YYYY-MM-DD HH:mm"),
    };
  });
};

const extractVariablesFromObjectKeyString = (ref, field) => {
  if (!ref) return null;

  // Remove field from obj eg. groups(...) to (...)
  const refArgs = ref.replace(field, "");

  // Convert string to object & remove brackets ()
  const object = JSON.parse(refArgs.substring(1, refArgs.length - 1));

  return object;
};

const getExistingObjectKey = (existing, incoming, target) => {
  const incomingObjectKey = Object.keys(incoming).find((key) =>
    key.startsWith(target),
  );
  const incomingObjectVariables = extractVariablesFromObjectKeyString(
    incomingObjectKey,
    target,
  );
  const incomingObjectVariablesKeys = Object.keys(incomingObjectVariables);

  const existingObjectKey = Object.keys(existing).find((key) => {
    if (key.startsWith(target)) {
      const existingObjectVariables = extractVariablesFromObjectKeyString(
        key,
        target,
      );
      const existingObjectVariablesKeys = Object.keys(existingObjectVariables);

      /* 
        Variables may contain [id, limit, offset].
        Groups section id will change when we switch to other section, 
        while Agent section offset is the one that change when fetchMore is called.
        This will ensure apollo return the exact object key.
      */
      if (
        key !== incomingObjectKey &&
        existingObjectVariables.id !== incomingObjectVariables.id
      ) {
        return false;
      }

      return isEqual(existingObjectVariablesKeys, incomingObjectVariablesKeys);
    }
  });

  return existingObjectKey;
};

/*
  Merge reporting cache data based on reporting category:
    - For team category: Updates existing team data with incoming team data
    - For other categories (groups, agents, etc): 
      - If incoming data is paginated, updates existing target reporting key with the data
      - Otherwise appends incoming data to existing
*/
export const mergeReportingCache = ({ existing, incoming, variables }) => {
  const { targetReportingCategory } = variables;

  if (targetReportingCategory === reportingEnum.reportingCategoryEnum.team) {
    const existingTeamObject = existing.team;
    const incomingTeamObject = incoming.team;
    const team = { ...existingTeamObject, ...incomingTeamObject };
    return { ...existing, team };
  }

  const existingObjectKey = getExistingObjectKey(
    existing,
    incoming,
    targetReportingCategory,
  );
  const incomingObjectKey = Object.keys(incoming).find((key) =>
    key.startsWith(targetReportingCategory),
  );

  const isSameObjectKey = existingObjectKey === incomingObjectKey;
  const isPagination = existingObjectKey?.includes("offset");

  const result =
    isPagination && !isSameObjectKey
      ? {
          ...existing,
          [existingObjectKey]: unionWith(
            existing[existingObjectKey],
            incoming[incomingObjectKey],
            isEqual,
          ),
        }
      : { ...existing, ...incoming };

  return result;
};

const prepareChartData = ({ theme, chartData }) => {
  const commonDatasetConfig = {
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  };

  return {
    datasets: chartData.map((data) => {
      return {
        ...commonDatasetConfig,
        ...data,
      };
    }),
  };
};

/* If data is below 3 hours, format as minutes, otherwise hours (10800 seconds = 3 hours) */
const getTimeConversion = ({ reportData, yAxisId }) => {
  const threshold = 10800;
  const isHourFormat = reportData.some((data) => data[yAxisId] >= threshold);
  return isHourFormat ? 3600 : 60;
};

const prepareChartOptions = ({
  theme,
  dateRange,
  yAxisTitle,
  isEmptyChartData,
  ...otherProps
} = {}) => {
  const defaultLabelColor = theme.palette.textPrimary.main;

  const { from, to } = dateRange;
  const { isSameDay, isBetween7Days } = getDatetimeDifference(
    moment(from),
    moment(to),
  );
  const isHourlyData = isSameDay || isBetween7Days;

  return {
    responsive: true,
    maintainAspectRatio: false,
    minBarLength: 5,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        bodyAlign: "center",
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.grey[1500],
        bodyColor: theme.palette.textPrimary.main,
        titleColor: theme.palette.textPrimary.main,
        borderWidth: 1,
        cornerRadius: 5,
        caretSize: 0,
        caretPadding: 5,
        titleFont: { size: 14 },
        bodyFont: { size: 14 },
        callbacks: {
          label: (context) => {
            const { dataset, formattedValue } = context;
            /* TODO: Find a better way to format time data */
            const timeConversion = dataset.data[0]?.timeConversion;

            if (timeConversion) {
              return dateUtils.formatDuration(formattedValue * timeConversion);
            }

            return `${formattedValue} ${dataset?.label?.toLowerCase()}`;
          },
        },
      },
    },
    scales: {
      x: {
        display: !isEmptyChartData,
        type: "time",
        grid: {
          offset: false,
        },
        stacked: true,
        time: {
          round: isHourlyData ? "hour" : "day",
          unit: isHourlyData ? "hour" : "day",
          stepSize: 1,
          displayFormats: {
            second: "HH:mm",
            minute: "HH:mm",
            hour: "HH:mm",
            day: "D MMM",
          },
          tooltipFormat: isHourlyData
            ? "MMM D, YYYY (ddd) HH:mm A"
            : "MMM D, YYYY (ddd)",
        },
        ticks: {
          maxRotation: 0,
          major: {
            enabled: true,
          },
          color: defaultLabelColor,
          font: (context) => {
            const commonFontStyle = {
              family: "'Roboto'",
              size: 14,
              weight: "400",
            };

            if (context.tick && context.tick.major) {
              return {
                ...commonFontStyle,
                size: 16,
                weight: "700",
              };
            }

            return commonFontStyle;
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          drawTicks: false,
        },
        stacked: true,
        ticks: {
          stepSize: 1,
          color: defaultLabelColor,
          padding: 8,
        },
        title: {
          display: true,
          text: yAxisTitle,
          color: defaultLabelColor,
          font: {
            family: "'Roboto'",
          },
        },
      },
    },
    ...otherProps,
  };
};

const getConversationReportYAxisTitle = ({
  currentReportOverviewTabKey,
  timeConversion,
}) => {
  const timeFormat = timeConversion === 3600 ? "hour" : "min";

  switch (currentReportOverviewTabKey) {
    case reportingEnum.conversationReportingOverviewTabEnum
      .totalNewConversations:
      return "# conversations";
    case reportingEnum.conversationReportingOverviewTabEnum.totalMessages:
      return "# message";
    case reportingEnum.conversationReportingOverviewTabEnum
      .averageFirstReplySpeed:
      return `Avg. First Reply Speed (${timeFormat})`;
    case reportingEnum.conversationReportingOverviewTabEnum.averageReplySpeed:
      return `Avg. Reply Speed (${timeFormat})`;
    case reportingEnum.conversationReportingOverviewTabEnum
      .averageMessagesPerConversation:
      return "# message";
    case reportingEnum.conversationReportingOverviewTabEnum
      .averageResolutionTime:
      return `Avg. Resolution Time (${timeFormat})`;
    case reportingEnum.conversationReportingOverviewTabEnum.averageCSAT:
      return "Avg. CSAT (Score)";
  }
};

const getMessagingOutcomeReportYAxisTitle = ({
  currentReportOverviewTabKey,
}) => {
  switch (currentReportOverviewTabKey) {
    case reportingEnum.messageOutcomeReportingOverviewTabEnum
      .contactsAttemptedCount:
    case reportingEnum.messageOutcomeReportingOverviewTabEnum
      .contactsSuccessfulCount:
    case reportingEnum.messageOutcomeReportingOverviewTabEnum
      .contactsFailureCount:
    case reportingEnum.messageOutcomeReportingOverviewTabEnum.contactsReadCount:
    case reportingEnum.messageOutcomeReportingOverviewTabEnum
      .contactsPendingCount: {
      return "# contacts";
    }
  }
};

const getVoiceSummaryReportYAxisTitle = ({
  currentReportOverviewTabKey,
  timeConversion,
}) => {
  const timeFormat = timeConversion === 3600 ? "hour" : "min";

  switch (currentReportOverviewTabKey) {
    case reportingEnum.voiceReportingOverviewTabEnum.totalCallTime:
      return `Total duration (${timeFormat})`;
    case reportingEnum.voiceReportingOverviewTabEnum.averageCallTime:
      return `Avg. Duration (${timeFormat})`;
    case reportingEnum.voiceReportingOverviewTabEnum.totalAnsweredCallCount:
      return "# of connected voice calls";
    case reportingEnum.voiceReportingOverviewTabEnum.inboundCallCount:
      return "# of connected inbound calls";
    case reportingEnum.voiceReportingOverviewTabEnum.outboundCallCount:
      return "# of connected outbound calls";
    case reportingEnum.voiceReportingOverviewTabEnum.cancelledCallCount:
      return `# of cancelled calls`;
    case reportingEnum.voiceReportingOverviewTabEnum.totalMissedCallCount:
    case reportingEnum.voiceReportingOverviewTabEnum.missedCallCount:
      return `# of missed calls`;
    case reportingEnum.voiceReportingOverviewTabEnum.voicemailCount:
      return "# of voicemails";
  }
};

const getCommonReportChartProps = ({
  chartData,
  chartOptions = {},
  dateRange,
  theme,
}) => {
  return {
    data: prepareChartData({ theme, chartData }),
    options: prepareChartOptions({
      theme,
      dateRange,
      isEmptyChartData: isEmpty(chartData),
      ...chartOptions,
    }),
  };
};

export const getConversationReportChartProps = ({
  reportData,
  currentReportOverviewTabKey,
  dateRange,
  theme,
}) => {
  const commonChartOptions = {
    yAxisTitle: getConversationReportYAxisTitle({
      currentReportOverviewTabKey,
    }),
  };

  if (isEmpty(reportData)) {
    return getCommonReportChartProps({
      chartData: [],
      chartOptions: commonChartOptions,
      currentReportOverviewTabKey,
      dateRange,
      theme,
    });
  }

  const otherChartProps = (() => {
    switch (currentReportOverviewTabKey) {
      case reportingEnum.conversationReportingOverviewTabEnum
        .totalNewConversations: {
        const totalNewConversationData = reportData.map((data) => {
          const { datetime: x, total: y } = data;
          return { x, y };
        });

        const chartData = [
          { label: "Conversations", data: totalNewConversationData },
        ];

        return { chartData };
      }

      case reportingEnum.conversationReportingOverviewTabEnum.totalMessages: {
        const inBoundMetrics = reportData.inBoundMetrics.map(
          (inboundMetric) => {
            const { datetime: x, inbound: y } = inboundMetric;
            return { x, y };
          },
        );

        const outBoundMetrics = reportData.outBoundMetrics.map(
          (outboundMetric) => {
            const { datetime: x, outbound: y } = outboundMetric;
            return { x, y };
          },
        );

        const chartData = [
          {
            label: "Inbound Messages",
            data: inBoundMetrics,
            borderColor: theme.palette.chart.background.blue,
            backgroundColor: theme.palette.chart.background.blue,
          },
          {
            label: "Outbound Messages",
            data: outBoundMetrics,
            borderColor: theme.palette.chart.background.green,
            backgroundColor: theme.palette.chart.background.green,
          },
        ];

        return { chartData, chartOptions: { showLegend: true } };
      }

      case reportingEnum.conversationReportingOverviewTabEnum
        .averageFirstReplySpeed: {
        const timeConversion = getTimeConversion({
          reportData,
          yAxisId: "averageTime",
        });
        const averageFirstReplySpeedData = reportData.map((data) => {
          const { datetime: x, averageTime } = data;
          return {
            x,
            y: averageTime ? averageTime / timeConversion : null,
            timeConversion,
          };
        });

        const chartData = [{ data: averageFirstReplySpeedData }];

        return {
          chartData,
          chartOptions: {
            yAxisTitle: getConversationReportYAxisTitle({
              currentReportOverviewTabKey,
              timeConversion,
            }),
          },
        };
      }

      case reportingEnum.conversationReportingOverviewTabEnum
        .averageReplySpeed: {
        const timeConversion = getTimeConversion({
          reportData,
          yAxisId: "averageTime",
        });
        const averageReplySpeedData = reportData.map((data) => {
          const { datetime: x, averageTime } = data;
          return {
            x,
            y: averageTime ? averageTime / timeConversion : null,
            timeConversion,
          };
        });

        const chartData = [{ data: averageReplySpeedData }];

        return {
          chartData,
          chartOptions: {
            yAxisTitle: getConversationReportYAxisTitle({
              currentReportOverviewTabKey,
              timeConversion,
            }),
          },
        };
      }

      case reportingEnum.conversationReportingOverviewTabEnum
        .averageMessagesPerConversation: {
        const inBoundMetrics = reportData.inBoundMetrics.map(
          (inboundMetric) => {
            const { datetime: x, averageInbound: y } = inboundMetric;
            return { x, y };
          },
        );

        const outBoundMetrics = reportData.outBoundMetrics.map(
          (outboundMetric) => {
            const { datetime: x, averageOutbound: y } = outboundMetric;
            return { x, y };
          },
        );

        const chartData = [
          {
            label: "Inbound Messages",
            data: inBoundMetrics,
            borderColor: theme.palette.chart.background.blue,
            backgroundColor: theme.palette.chart.background.blue,
          },
          {
            label: "Outbound Messages",
            data: outBoundMetrics,
            borderColor: theme.palette.chart.background.green,
            backgroundColor: theme.palette.chart.background.green,
          },
        ];

        return { chartData, chartOptions: { showLegend: true } };
      }

      case reportingEnum.conversationReportingOverviewTabEnum
        .averageResolutionTime: {
        const timeConversion = getTimeConversion({
          reportData,
          yAxisId: "averageTime",
        });
        const averageResolutionTimeData = reportData.map((data) => {
          const { datetime: x, averageTime } = data;
          return {
            x,
            y: averageTime ? averageTime / timeConversion : null,
            timeConversion,
          };
        });

        const chartData = [{ data: averageResolutionTimeData }];

        return {
          chartData,
          chartOptions: {
            yAxisTitle: getConversationReportYAxisTitle({
              currentReportOverviewTabKey,
              timeConversion,
            }),
          },
        };
      }

      case reportingEnum.conversationReportingOverviewTabEnum.averageCSAT: {
        const averageCSATData = reportData.map((data) => {
          const { datetime: x, average: y } = data;
          return { x, y };
        });

        const chartData = [{ label: "Score", data: averageCSATData }];

        return { chartData };
      }

      case reportingEnum.conversationReportingOverviewTabEnum
        .totalUnattendedConversations: {
        const totalUnattendedConversationData = reportData.map((data) => {
          const { datetime: x, total: y } = data;
          return { x, y };
        });

        const chartData = [
          { label: "Unattended", data: totalUnattendedConversationData },
        ];

        return { chartData };
      }

      default:
        break;
    }
  })();

  const { chartData, chartOptions } = otherChartProps || {};

  return getCommonReportChartProps({
    chartData,
    chartOptions: { ...commonChartOptions, ...chartOptions },
    currentReportOverviewTabKey,
    dateRange,
    theme,
  });
};

export const getMessagingOutcomeReportChartProps = ({
  reportData,
  currentReportOverviewTabKey,
  dateRange,
  theme,
}) => {
  const commonChartOptions = {
    yAxisTitle: getMessagingOutcomeReportYAxisTitle({
      currentReportOverviewTabKey,
    }),
  };

  if (isEmpty(reportData)) {
    return getCommonReportChartProps({
      chartData: [],
      chartOptions: commonChartOptions,
      currentReportOverviewTabKey,
      dateRange,
      theme,
    });
  }

  const otherChartProps = (() => {
    switch (currentReportOverviewTabKey) {
      case reportingEnum.messageOutcomeReportingOverviewTabEnum
        .contactsAttemptedCount: {
        const contactsSuccessfulCountMetricsPerPeriod =
          reportData.contactsSuccessfulCountMetricsPerPeriod.map((metric) => {
            const { datetime: x, contactsSuccessfulCount: y } = metric;
            return { x, y };
          });

        const contactsPendingCountMetricsPerPeriod =
          reportData.contactsPendingCountMetricsPerPeriod.map((metric) => {
            const { datetime: x, contactsPendingCount: y } = metric;
            return { x, y };
          });

        const contactsFailureCountMetricsPerPeriod =
          reportData.contactsFailureCountMetricsPerPeriod.map((metric) => {
            const { datetime: x, contactsFailureCount: y } = metric;
            return { x, y };
          });

        const chartData = [
          {
            label: "Successful",
            data: contactsSuccessfulCountMetricsPerPeriod,
            borderColor: theme.palette.chart.background.green,
            backgroundColor: theme.palette.chart.background.green,
            tooltipTitle: "Message sent & read",
          },
          {
            label: "Pending",
            data: contactsPendingCountMetricsPerPeriod,
            borderColor: theme.palette.chart.background.grey,
            backgroundColor: theme.palette.chart.background.grey,
            tooltipTitle: "Message pending to be received",
          },
          {
            label: "Fail",
            data: contactsFailureCountMetricsPerPeriod,
            borderColor: theme.palette.chart.background.red,
            backgroundColor: theme.palette.chart.background.red,
            tooltipTitle: "Message failed to be sent",
          },
        ];

        return { chartData, chartOptions: { showLegend: true } };
      }

      case reportingEnum.messageOutcomeReportingOverviewTabEnum
        .contactsSuccessfulCount: {
        const data = reportData.map((data) => {
          const { datetime: x, contactsSuccessfulCount: y } = data;
          return { x, y };
        });

        const chartData = [{ label: "Contacts", data }];

        return { chartData };
      }

      case reportingEnum.messageOutcomeReportingOverviewTabEnum
        .contactsFailureCount: {
        const data = reportData.map((data) => {
          const { datetime: x, contactsFailureCount: y } = data;
          return { x, y };
        });

        const chartData = [
          {
            label: "Contacts",
            data,
            borderColor: theme.palette.chart.background.red,
            backgroundColor: theme.palette.chart.background.red,
          },
        ];

        return { chartData };
      }

      case reportingEnum.messageOutcomeReportingOverviewTabEnum
        .contactsReadCount: {
        const data = reportData.map((data) => {
          const { datetime: x, contactsReadCount: y } = data;
          return { x, y };
        });

        const chartData = [{ label: "Contacts", data }];

        return { chartData };
      }

      case reportingEnum.messageOutcomeReportingOverviewTabEnum
        .contactsPendingCount: {
        const data = reportData.map((data) => {
          const { datetime: x, contactsPendingCount: y } = data;
          return { x, y };
        });

        const chartData = [
          {
            label: "Contacts",
            data,
            borderColor: theme.palette.chart.background.grey,
            backgroundColor: theme.palette.chart.background.grey,
          },
        ];

        return { chartData };
      }

      default:
        break;
    }
  })();

  const { chartData, chartOptions } = otherChartProps || {};

  return getCommonReportChartProps({
    chartData,
    chartOptions: { ...commonChartOptions, ...chartOptions },
    currentReportOverviewTabKey,
    dateRange,
    theme,
  });
};

const prepareVoiceReportCountChartProps = ({
  reportMetricKey,
  reportData,
  theme,
}) => {
  const inBoundMetrics =
    reportData.inboundVoiceCallReport?.metricsPerPeriod.map((inboundMetric) => {
      const { datetime: x } = inboundMetric;
      return { x, y: inboundMetric[reportMetricKey] };
    });

  const outBoundMetrics =
    reportData.outboundVoiceCallReport?.metricsPerPeriod.map(
      (outboundMetric) => {
        const { datetime: x } = outboundMetric;
        return { x, y: outboundMetric[reportMetricKey] };
      },
    );

  const chartData = [
    {
      label: "Inbound Calls",
      data: inBoundMetrics,
      borderColor: theme.palette.chart.background.blue,
      backgroundColor: theme.palette.chart.background.blue,
    },
    {
      label: "Outbound Calls",
      data: outBoundMetrics,
      borderColor: theme.palette.chart.background.green,
      backgroundColor: theme.palette.chart.background.green,
    },
  ];

  return { chartData, chartOptions: { showLegend: true } };
};

export const getVoiceReportChartProps = ({
  reportData,
  currentVoiceCallTypeTabKey,
  currentReportOverviewTabKey,
  dateRange,
  theme,
}) => {
  const commonChartOptions = {
    yAxisTitle: getVoiceSummaryReportYAxisTitle({
      currentReportOverviewTabKey,
    }),
  };

  if (isEmpty(reportData)) {
    return getCommonReportChartProps({
      chartData: [],
      chartOptions: commonChartOptions,
      currentReportOverviewTabKey,
      dateRange,
      theme,
    });
  }

  const otherChartProps = (() => {
    switch (currentReportOverviewTabKey) {
      case reportingEnum.voiceReportingOverviewTabEnum.totalAnsweredCallCount: {
        return prepareVoiceReportCountChartProps({
          reportMetricKey: "answeredCallCount",
          reportData,
          theme,
        });
      }

      case reportingEnum.voiceReportingOverviewTabEnum.totalMissedCallCount: {
        return prepareVoiceReportCountChartProps({
          reportMetricKey: "missedCallCount",
          reportData,
          theme,
        });
      }

      case reportingEnum.voiceReportingOverviewTabEnum.totalCallTime:
      case reportingEnum.voiceReportingOverviewTabEnum.averageCallTime: {
        const voiceCallReportObjectKey =
          voiceCallReportQueryObjectMapping[currentVoiceCallTypeTabKey];

        const voiceCallReport = reportData[voiceCallReportObjectKey];

        const timeConversion = getTimeConversion({
          reportData: voiceCallReport?.metricsPerPeriod,
          yAxisId: currentReportOverviewTabKey,
        });

        const data = voiceCallReport?.metricsPerPeriod.map((metric) => {
          const { datetime: x } = metric;
          const metricData = metric[currentReportOverviewTabKey];
          return {
            x,
            y: metricData ? metricData / timeConversion : null,
            timeConversion,
          };
        });

        const chartData = [{ data }];

        return {
          chartData,
          chartOptions: {
            yAxisTitle: getVoiceSummaryReportYAxisTitle({
              currentReportOverviewTabKey,
              timeConversion,
            }),
          },
        };
      }

      case reportingEnum.voiceReportingOverviewTabEnum.inboundCallCount:
      case reportingEnum.voiceReportingOverviewTabEnum.outboundCallCount: {
        const voiceCallTypeTabKey =
          currentReportOverviewTabKey ===
          reportingEnum.voiceReportingOverviewTabEnum.inboundCallCount
            ? reportingEnum.voiceReportingTypeTabEnum.inboundCalls
            : reportingEnum.voiceReportingTypeTabEnum.outboundCalls;

        const voiceCallReportObjectKey =
          voiceCallReportQueryObjectMapping[voiceCallTypeTabKey];

        const voiceCallReport = reportData[voiceCallReportObjectKey];

        const data = voiceCallReport?.metricsPerPeriod.map((metric) => {
          const { datetime: x } = metric;
          return { x, y: metric.answeredCallCount };
        });

        const chartData = [
          {
            label: startCase(voiceCallTypeTabKey),
            data,
            borderColor:
              currentReportOverviewTabKey ===
              reportingEnum.voiceReportingOverviewTabEnum.inboundCallCount
                ? theme.palette.chart.background.blue
                : theme.palette.chart.background.green,
            backgroundColor:
              currentReportOverviewTabKey ===
              reportingEnum.voiceReportingOverviewTabEnum.inboundCallCount
                ? theme.palette.chart.background.blue
                : theme.palette.chart.background.green,
          },
        ];

        return { chartData };
      }

      case reportingEnum.voiceReportingOverviewTabEnum.voicemailCount: {
        const data = reportData.inboundVoiceCallReport?.metricsPerPeriod.map(
          (inboundMetric) => {
            const { datetime: x } = inboundMetric;
            return { x, y: inboundMetric[currentReportOverviewTabKey] };
          },
        );

        const chartData = [{ label: "Inbound Calls", data }];

        return { chartData };
      }

      /* Default case handle parsing call count with single dataset eg. cancelledCallCount & missedCallCount */
      default: {
        const voiceCallReportObjectKey =
          voiceCallReportQueryObjectMapping[currentVoiceCallTypeTabKey];

        const voiceCallReport = reportData[voiceCallReportObjectKey];

        const data = voiceCallReport?.metricsPerPeriod.map((metric) => {
          const { datetime: x } = metric;
          return { x, y: metric[currentReportOverviewTabKey] };
        });

        const chartData = [
          { label: startCase(currentVoiceCallTypeTabKey), data },
        ];

        return { chartData };
      }
    }
  })();

  const { chartData, chartOptions } = otherChartProps || {};

  return getCommonReportChartProps({
    chartData,
    chartOptions: { ...commonChartOptions, ...chartOptions },
    currentReportOverviewTabKey,
    dateRange,
    theme,
  });
};

export const getFilterIds = (filtersArray) => {
  const mappedFiltersArray = filtersArray.map(({ filter }) => ({
    filterType: filter.filterType,
    ids: map(filter.selectorValue, "value"),
  }));

  const filteredArray = keyBy(mappedFiltersArray, "filterType");

  const conversationTagIds =
    filteredArray[reportingEnum.reportingFilterTypeEnum.conversationTags].ids;
  const contactTagIds =
    filteredArray[reportingEnum.reportingFilterTypeEnum.contactTags].ids;

  return {
    ...(!isEmpty(conversationTagIds) && { conversationTagIds }),
    ...(!isEmpty(contactTagIds) && { contactTagIds }),
  };
};

export const prepareCommonReportingVariables = (formValues) => {
  const { dateRange, filters } = formValues;
  const { from, to } = dateRange;
  const startDatetime = moment(from).format();
  const endDatetime = moment(to).format();

  return {
    startDatetime,
    endDatetime,
    truncateType: getChartTruncateType({ startDatetime, endDatetime }),
    ...getFilterIds(filters),
  };
};

export const getVoiceCallLogsFilterVariables = (voiceCallLogsFilters) => {
  const parsedFilters = voiceCallLogsFilters.map(({ filter }) => {
    if (isEmpty(filter.selectorValue)) return null;

    switch (filter.filterType) {
      case reportingEnum.voiceCallLogsFilterTypeEnum.callDirection: {
        return { callDirection: filter.selectorValue.value };
      }

      case reportingEnum.reportingFilterTypeEnum.contactTags: {
        return { contactTagIds: map(filter.selectorValue, "value") };
      }

      case reportingEnum.voiceCallLogsFilterTypeEnum.firstAgentInCall: {
        return { firstAgentInCallIds: map(filter.selectorValue, "value") };
      }

      case reportingEnum.voiceCallLogsFilterTypeEnum.status: {
        return { conversationStatus: filter.selectorValue.value };
      }

      case reportingEnum.reportingFilterTypeEnum.conversationTags: {
        return { conversationTagIds: map(filter.selectorValue, "value") };
      }

      case reportingEnum.reportingFilterTypeEnum.group: {
        return { conversationGroupIds: map(filter.selectorValue, "value") };
      }

      case reportingEnum.reportingFilterTypeEnum.agent: {
        return { conversationAgentIds: map(filter.selectorValue, "value") };
      }

      case reportingEnum.voiceCallLogsFilterTypeEnum.callStarted: {
        const { dateRange } = filter.selectorValue;
        const { from, to } = dateRange;
        return { createdGte: from, createdLt: to };
      }

      case reportingEnum.voiceCallLogsFilterTypeEnum.duration: {
        const { durationGte, durationLte } = JSON.parse(
          filter.selectorValue.value,
        );
        return {
          activeCallDurationGte: durationGte,
          activeCallDurationLte: durationLte,
        };
      }

      case reportingEnum.voiceCallLogsFilterTypeEnum.speedToAnswer: {
        const { durationGte, durationLte } = JSON.parse(
          filter.selectorValue.value,
        );
        return {
          initialSpeedToAnswerGte: durationGte,
          initialSpeedToAnswerLte: durationLte,
        };
      }

      default:
        return null;
    }
  });

  return fromPairs(flatMap(parsedFilters, toPairs));
};

export const prepareCSATReportingVariables = (formValues) => {
  const { csatRange, withFeedback } = formValues;
  const [minScore, maxScore] = csatRange;
  const reportingVariables = prepareCommonReportingVariables(formValues);

  return {
    minScore,
    maxScore,
    withFeedback,
    ...reportingVariables,
  };
};

export const prepareReportingFilterSelectOptions = ({
  data,
  selectorOptionSettings,
  queryObjectListPath,
}) => {
  const optionsData = get(data, queryObjectListPath);

  const options = commonUtils.prepareAsyncSelectOptions({
    optionsData,
    ...selectorOptionSettings,
  });

  return options;
};
