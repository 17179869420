const parsePhoneNumberErrorEnum = Object.freeze({
  TOO_SHORT: "TOO_SHORT",
  TOO_LONG: "TOO_LONG",
  INVALID_LENGTH: "INVALID_LENGTH",
  INVALID_COUNTRY: "INVALID_COUNTRY",
  NOT_A_NUMBER: "NOT_A_NUMBER",
});

export const parsePhoneNumberErrorMessage = Object.freeze({
  [parsePhoneNumberErrorEnum.TOO_LONG]: "Phone number is too long",
  [parsePhoneNumberErrorEnum.TOO_SHORT]: "Phone number is too short",
  [parsePhoneNumberErrorEnum.INVALID_LENGTH]: "Phone number length is invalid",
  [parsePhoneNumberErrorEnum.INVALID_COUNTRY]:
    "Please start with a correct country code, e.g. +60",
  [parsePhoneNumberErrorEnum.NOT_A_NUMBER]: "Invalid phone number",
});
