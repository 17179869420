const namespace = "@/userActions";

export const GET_ACCESS_TOKEN_SUCCESS = `${namespace}/GET_ACCESS_TOKEN_SUCCESS`;
export const SET_CHAT_MESSAGE_EDITOR_DRAFT = `${namespace}/SET_CHAT_MESSAGE_EDITOR_DRAFT`;

export const LOG_OUT_REQUEST = `${namespace}/LOG_OUT_REQUEST`;
export const LOG_OUT_SUCCESS = `${namespace}/LOG_OUT_SUCCESS`;

export const SET_EDIT_AI_RESPONSE = `${namespace}/SET_EDIT_AI_RESPONSE`;

export const setChatMessageEditorDraft = ({ key, value }) => ({
  type: SET_CHAT_MESSAGE_EDITOR_DRAFT,
  key,
  value,
});

export const getAccessTokenSuccess = ({ accessToken }) => ({
  type: GET_ACCESS_TOKEN_SUCCESS,
  accessToken,
});

export const logOutRequest = () => ({
  type: LOG_OUT_REQUEST,
});

export const logOutSuccess = () => ({
  type: LOG_OUT_SUCCESS,
});

export const setEditAiResponse = ({ clientReference, editLog }) => {
  return { type: SET_EDIT_AI_RESPONSE, clientReference, editLog };
};
