import * as sharedConversationDefinitions from "@/definitions/conversation/sharedConversationDefinitions";

export const voiceReportMetricsVarDefinition = `
  $startDatetime: DateTime!
  $endDatetime: DateTime!
  $conversationTagIds: [ID]
  $contactTagIds: [ID]
`;

export const voiceReportMetricsPerPeriodVarDefinition = `
  $startDatetime: DateTime!
  $endDatetime: DateTime!
  $truncateType: String
  $conversationTagIds: [ID]
  $contactTagIds: [ID]
`;

const voiceReportMetricsArguments = `
  startDatetime: $startDatetime
  endDatetime: $endDatetime
  conversationTagIds: $conversationTagIds
  contactTagIds: $contactTagIds
`;

const voiceReportMetricsPerPeriodArguments = `
  startDatetime: $startDatetime
  endDatetime: $endDatetime
  truncateType: $truncateType
  conversationTagIds: $conversationTagIds
  contactTagIds: $contactTagIds
`;

export const voiceCallLogsDefinition = `{
  id
  created
  callDirection
  callStatus: conversationStatus
  activeCallDuration
  initialSpeedToAnswer
  
  firstAgentInCall ${sharedConversationDefinitions.agentDefinition}
  
  movedToChatConversation {
    id
    status
  }
    
  voiceProviderContact {
    id
    contact {
      id
      contactTags {
        id 
        contentTypeAllowedTag ${sharedConversationDefinitions.contentTypeAllowedTagDefinition}
      }
    }
  }
  
  conversation {
    id
    assignee ${sharedConversationDefinitions.agentDefinition}
    contact ${sharedConversationDefinitions.contactDefinition}
    conversationTags ${sharedConversationDefinitions.conversationTagsDefinition}
    group ${sharedConversationDefinitions.groupDefinition}
  }
}`;

const audioDefinition = `{
  id
  duration
  media {
    uuid
    mediaFileUrl
  }
}`;

const voiceConversationDateTimestampsDefition = `
  abandonedAt
  busyAt
  cancelledAt
  completedAt
  declinedAt
  disconnectedAt
  endedAt
  failedAt
  ivrAbandonedAt
  lastOnHoldAt
  leftVoicemailAt
  missedAt
  movedToChatAt
  shortAbandonedAt
  timeoutAt
  transferDeclinedAt
  transferFailedAt
  transferLeftVoicemailAt
  transferMissedAt
  transferMovedToChatAt
  transferTimeoutAt
`;

const voiceCallLogDetailLegInfoDefinition = `{
  description
  startedAt
  duration
  speedToAnswer
  agent ${sharedConversationDefinitions.agentDefinition}
  voiceProviderContact ${sharedConversationDefinitions.voiceProviderContactDefinition}
}`;

export const voiceCallLogsFormDataDefinition = `{
  id
  created
  callDirection
  callStatus: conversationStatus
  activeCallDuration
  recording ${audioDefinition}
  voicemail ${audioDefinition}
  initiatorVoiceLeg {
    id
    voiceProviderAccount {
      id
      name
    }
  }
  voiceLegs {
    id
    voiceCallLogDetailLegInfo ${voiceCallLogDetailLegInfoDefinition}
  }
  conversation {
    id
    conversationStatus: status
    assignee ${sharedConversationDefinitions.agentDefinition}
    contact ${sharedConversationDefinitions.contactDefinition}
    resolver {
      ... on AgentObject ${sharedConversationDefinitions.agentDefinition}
      ... on BotUserObject  ${sharedConversationDefinitions.senderBotUserDefinition}
    }
  }
  ${voiceConversationDateTimestampsDefition}
}`;

export const transcriptionSegmentsDefinition = `{
  id
  isTimestampAccurate
  startTimestamp
  segmentText
  voiceLeg {
    id
    agent ${sharedConversationDefinitions.agentDefinition}
    voiceProviderContact ${sharedConversationDefinitions.voiceProviderContactDefinition}
  }
}`;

/* Below are the definitions used in voiceReportingQueries.js */

export const voiceCallReportOverviewMetricsDefinition = `
  allVoiceCallReport {
    metrics(${voiceReportMetricsArguments}) {
      averageCallTime
    }
  }

  inboundVoiceCallReport {
    metrics(${voiceReportMetricsArguments}) {
      totalCallTime
      averageCallTime
      answeredCallCount
      missedCallCount
      voicemailCount
    }
  }
    
  outboundVoiceCallReport {
    metrics(${voiceReportMetricsArguments}) {
      totalCallTime
      averageCallTime
      answeredCallCount
      missedCallCount
      cancelledCallCount
    }
  }
`;

export const allCallsVoiceReportTableMetricsDefinition = `
  inboundVoiceCallReport {
    metrics(${voiceReportMetricsArguments}) {
      answeredCallCount
      totalCallCount
      totalCallTime
      missedCallCount
      voicemailCount
    }
  }
  outboundVoiceCallReport {
    metrics(${voiceReportMetricsArguments}) {
      answeredCallCount
      totalCallCount
      totalCallTime
      missedCallCount
    }
  }
`;

export const uniqueContactsVoiceReportTableMetricsDefinition = `
  inboundVoiceCallReport {
    metrics(${voiceReportMetricsArguments}) {
      uniqueAnsweredCallCount
      uniqueTotalCallCount
    }
  }
  outboundVoiceCallReport {
    metrics(${voiceReportMetricsArguments}) {
      uniqueAnsweredCallCount
      uniqueTotalCallCount
    }
  }
`;

/* Below are the definitions used in allVoiceCallReportQueries.js */

export const totalAnsweredVoiceCallCountReportDefinition = `
  inboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      answeredCallCount
    }
  }
  outboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      answeredCallCount
    }
  }
`;

export const totalMissedVoiceCallCountReportDefinition = `
  inboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      missedCallCount
    }
  }
  outboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      missedCallCount
    }
  }
`;

export const allTotalVoiceCallTimeReportDefinition = `
  allVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
        datetime
        totalCallTime
    }
  }
`;

export const allAverageVoiceCallTimeReportDefinition = `
  allVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      averageCallTime
    }
  }
`;

/* Below are the definitions used in outboundVoiceCallReportQueries.js */

export const outboundVoiceCallCountReportDefinition = `
  outboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      answeredCallCount
    }
  }
`;

export const outboundTotalVoiceCallTimeReportDefinition = `
  outboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      totalCallTime
    }
  }
`;

export const outboundAverageVoiceCallTimeReportDefinition = `
  outboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      averageCallTime
    }
  }
`;

export const outboundMissedVoiceCallCountReportDefinition = `
  outboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      missedCallCount
    }
  }
`;

export const outboundCancelledVoiceCallCountReportDefinition = `
  outboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      cancelledCallCount
    }
  }
`;

/* Below are the definitions used in inboundVoiceCallReportQueries.js */

export const inboundVoiceCallCountReportDefinition = `
  inboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      answeredCallCount
    }
  }
`;

export const inboundTotalVoiceCallTimeReportDefinition = `
  inboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      totalCallTime
    }
  }
`;

export const inboundAverageVoiceCallTimeReportDefinition = `
  inboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      averageCallTime
    }
  }
`;

export const inboundMissedVoiceCallCountReportDefinition = `
  inboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      missedCallCount
    }
  }
`;

export const inboundVoicemailVoiceCallCountReportDefinition = `
  inboundVoiceCallReport {
    metricsPerPeriod(${voiceReportMetricsPerPeriodArguments}) {
      datetime
      voicemailCount
    }
  }
`;
