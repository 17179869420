import { filter, flatMap, flatten, flow, map, uniq } from "lodash";

export const getSelectedPermissionIds = ({ permissionCategories }) => {
  return flow(
    (value) => map(value, "permissions"),
    (value) => flatten(value),
    (value) => filter(value, { isEnabled: true }),
    (value) => map(value, "id"),
  )(permissionCategories);
};

export const getRolesPermissionCodes = (roles) => {
  return flow(
    (value) => flatMap(value, "permissionCategories"),
    (value) => flatMap(value, "permissions"),
    (value) => filter(value, { isEnabled: true }),
    (value) => map(value, "code"),
    (value) => uniq(value),
  )(roles);
};

export const isPermissionEnabled = (permissions) => {
  return permissions.some((permission) => permission.isEnabled);
};

export const updatePermissionCategory = (permissionCategory, isEnabled) => {
  const permissions = permissionCategory.permissions.map((permission) => ({
    ...permission,
    isEnabled,
  }));

  return {
    ...permissionCategory,
    isEnabled,
    permissions,
  };
};

export const prepareDefaultPermissionCategoriesData = (
  permissionCategories,
) => {
  return permissionCategories.map((permissionCategory) => {
    const isEnabled = false;
    return updatePermissionCategory(permissionCategory, isEnabled);
  });
};

export const prepareInitialPermissionCategoriesData = (
  permissionCategories,
) => {
  return permissionCategories.map((permissionCategory) => ({
    ...permissionCategory,
    isEnabled: isPermissionEnabled(permissionCategory.permissions),
  }));
};
