import { gql, useMutation } from "@apollo/client";
import { isArray, isBoolean, isPlainObject } from "lodash";

import { isListObject, removeInArray, reorderArray } from "./commonUtils";
import { empty } from "./noopUtils";

export const unpackData = (data) => Object.values(data)[0];
export const getMutationId = (data) => unpackData(data).id;
export const getMutationInstance = (data) => unpackData(data).instance;
export const getEntriesObjectData = (entries, objectId) => {
  if (!objectId) return null;
  return entries.find(({ id }) => objectId === id);
};

export const storeCreate = (
  cache,
  data,
  { name, fragment, objectIdFieldName = "id" },
) => {
  cache.modify({
    fields: {
      [name]: (existing = null) => {
        if (!existing) return existing;

        const mutationInstance = getMutationInstance(data);

        const newObjectRef = cache.writeFragment({
          id: `${mutationInstance.__typename}:${mutationInstance[objectIdFieldName]}`,
          data: mutationInstance,
          fragment: fragment,
        });

        if (isListObject({ data: existing })) {
          return {
            ...existing,
            results: [...existing.results, newObjectRef],
            totalCount: existing.totalCount + 1,
          };
        }

        return [...existing, newObjectRef];
      },
    },
  });
};

export const storeDelete = (cache, objectId, { name }) => {
  cache.modify({
    fields: {
      [name]: (existing = null, { readField }) => {
        if (!existing) return existing;

        if (isListObject({ data: existing })) {
          const results = existing.results.filter((data) => {
            return readField("id", data) !== objectId;
          });

          return {
            ...existing,
            results,
            totalCount: existing.totalCount - 1,
          };
        }

        return existing.filter((data) => {
          return readField("id", data) !== objectId;
        });
      },
    },
  });
};

const mockMutation = gql`
  mutation {
    mock
  }
`;

export const useSafeMutation = (mutation, options) => {
  const isFakeMutation = !mutation || options.skip;

  /* This function allows passing optional query to a mutation. */
  const result = useMutation(isFakeMutation ? mockMutation : mutation, options);

  const onExecute = () => {
    throw new Error(
      "This mutation was marked with `skip`, it's not possible to call it.",
    );
  };

  return isFakeMutation ? [onExecute, {}] : result;
};

export const getRootQueryKey = ({
  queryName = "",
  keyArgArray = [],
  isDefaultQueryCacheKeyShape = false,
}) => {
  const variableString = keyArgArray
    .map(({ keyArg, value }) => {
      const parsedValue = (() => {
        if (isPlainObject(value)) return JSON.stringify(value);
        if (isArray(value)) return `[${value.map((val) => `"${val}"`)}]`;
        if (isBoolean(value)) return `${value}`;
        return `"${value}"`;
      })();

      return `"${keyArg}":${parsedValue}`;
    })
    .join(",");

  if (isDefaultQueryCacheKeyShape) return `${queryName}({${variableString}})`;
  return `${queryName}:{${variableString}}`;
};

export const getNormalizedObjectData = ({ objectData, queryName }) => {
  if (!objectData) return {};

  const isObjectDataList = isArray(objectData[queryName]);
  const isList = isListObject({
    data: objectData,
    name: queryName,
  });
  return {
    [queryName]: isList
      ? objectData[queryName].results[0]
      : isObjectDataList
        ? objectData[queryName][0]
        : objectData[queryName],
  };
};

/*
  This function is used to get the new "results" and "totalCount" for a query
  when removing an item from the cache
*/
export const filterQueryResultsWithTotalCount = ({
  existing = {},
  equalityFn = empty,
}) => {
  const { results = [], totalCount = 0 } = existing;
  const removalIndex = results.findIndex(equalityFn);
  const isExistingItem = removalIndex >= 0;

  const newResults = isExistingItem
    ? removeInArray({ array: results, index: removalIndex })
    : results;

  const newTotalCount = isExistingItem ? totalCount - 1 : totalCount;

  return { newResults, newTotalCount };
};

const getOrderingParametersFromExisting = ({
  existing = {},
  insertAt,
  equalityFn = empty,
}) => {
  const { results = [], totalCount = 0 } = existing;
  const itemIndex = results.findIndex(equalityFn);
  const isExistingItem = itemIndex >= 0;
  const isInsertToBottom = insertAt < 0;

  const insertIndex = isInsertToBottom ? totalCount : insertAt;

  /* Don't insert to bottom if not everything has been fetched*/
  const isInsertIndexWithinExistingRange = isInsertToBottom
    ? insertIndex <= results.length
    : true;

  return {
    itemIndex,
    insertIndex,
    isExistingItem,
    isInsertIndexWithinExistingRange,
  };
};

/*
  This function is used to get the new "results" and "totalCount" for a query
  when adding an item to the cache
*/
export const addToQueryResultsWithTotalCount = ({
  existing = {},
  newItem,
  insertAt,
  equalityFn = empty,
}) => {
  const { results = [], totalCount = 0 } = existing;

  const { isExistingItem, insertIndex, isInsertIndexWithinExistingRange } =
    getOrderingParametersFromExisting({ existing, insertAt, equalityFn });

  const newResults =
    isExistingItem || !isInsertIndexWithinExistingRange
      ? results
      : [
          ...results.slice(0, insertIndex),
          newItem,
          ...results.slice(insertIndex),
        ];

  const newTotalCount = isExistingItem ? totalCount : totalCount + 1;

  return { newResults, newTotalCount };
};

export const reorderQueryItem = ({
  existing = {},
  insertAt,
  equalityFn = empty,
}) => {
  const { results = [] } = existing;

  const {
    itemIndex,
    insertIndex,
    isExistingItem,
    isInsertIndexWithinExistingRange,
  } = getOrderingParametersFromExisting({ existing, insertAt, equalityFn });

  const isReorder = isExistingItem && isInsertIndexWithinExistingRange;

  return isReorder ? reorderArray(results, itemIndex, insertIndex) : results;
};
