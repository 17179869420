export const conversationEventTypeEnum = Object.freeze({
  message: "MESSAGE",
  messageFailure: "MESSAGE_FAILURE",
  fallbackMessage: "FALLBACK_MESSAGE",
  conversationNote: "CONVERSATION_NOTE",
  conversationAssignment: "CONVERSATION_ASSIGNMENT",
  conversationTransfer: "CONVERSATION_TRANSFER",
  conversationCreated: "CONVERSATION_CREATED",
  conversationResolved: "CONVERSATION_RESOLVED",
  conversationPrioritySet: "CONVERSATION_PRIORITY_SET",
  conversationPriorityUnset: "CONVERSATION_PRIORITY_UNSET",
  conversationUnreadSet: "CONVERSATION_UNREAD_SET",
  conversationUnreadUnset: "CONVERSATION_UNREAD_UNSET",
  conversationUnattendedSet: "CONVERSATION_UNATTENDED_SET",
  conversationUnattendedUnset: "CONVERSATION_UNATTENDED_UNSET",
  conversationSummaryGenerated: "CONVERSATION_SUMMARY_GENERATED",
  conversationSummaryFailedToGenerate:
    "CONVERSATION_SUMMARY_FAILED_TO_GENERATE",
  conversationContactBlastingOptedIn: "CONVERSATION_CONTACT_BLASTING_OPTED_IN",
  conversationContactBlastingOptedOut:
    "CONVERSATION_CONTACT_BLASTING_OPTED_OUT",
  conversationContactIdleSet: "CONVERSATION_CONTACT_IDLE_SET",
  conversationContactIdleUnset: "CONVERSATION_CONTACT_IDLE_UNSET",
  agentMentioned: "AGENT_MENTIONED",
  paymentSessionInitiated: "PAYMENT_SESSION_INITIATED",
  paymentSessionCompleted: "PAYMENT_SESSION_COMPLETED",
  paymentSessionRefundInitiated: "PAYMENT_SESSION_REFUND_INITIATED",
  paymentSessionRefunded: "PAYMENT_SESSION_REFUNDED",
  paymentSessionCancellationInitiated: "PAYMENT_SESSION_CANCELLATION_INITIATED",
  paymentSessionCancelled: "PAYMENT_SESSION_CANCELLED",
  paymentSessionExpired: "PAYMENT_SESSION_EXPIRED",
  conversationTagCreated: "CONVERSATION_TAG_CREATED",
  conversationTagRemoved: "CONVERSATION_TAG_REMOVED",
  viewedLandingPageDocument: "VIEWED_LANDING_PAGE_DOCUMENT",
});
